import React from "react";
import styled from "styled-components";
import { mainBlue, mainRed, mainWhite, mainYellow } from "../variables";

const Wrapper = styled.div`
  background: ${(props) => props.bgColour};
  color: ${(props) => props.textColour};
  position: absolute;
  top: 6px;
  left: 6px;
  padding: 1px 8px;

  span {
    font-size: 0.6rem;
    text-transform: uppercase;
  }
`;

const Tag = ({ label }) => {
  let textColour;
  let bgColour;

  if (label === "Best seller") {
    textColour = mainBlue;
    bgColour = mainYellow;
  }

  if (label === "Out of stock") {
    textColour = mainWhite;
    bgColour = mainRed;
  }
  const isTag = label !== "None" && label !== "New product";

  return isTag ? (
    <Wrapper textColour={textColour} bgColour={bgColour}>
      <span>{label}</span>
    </Wrapper>
  ) : null;
};

export default Tag;
