import React, { useContext } from "react";
import styled from "styled-components";
import { mainBlue, mainWhite, screen } from "../variables";
import { motion } from "framer-motion";
import { StoreContext } from "../../context";
import imgBg from "../../images/F-Pak-Customise-Packaging-Mobile.jpg";

const Wrapper = styled.div`
  background-image: url(${imgBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
  height: 100%;
  color: ${mainWhite};
  display: flex;
  flex-direction: column;
  padding: 28px;
  min-height: 350px;
  @media ${screen.xsmall} {
    min-height: 280px;
  }

  h6 {
    font-size: 1.65rem;
  }

  p {
    font-size: 1.05rem;
    font-weight: 300;
    line-height: 1.4;
    margin: 15px 0 0 0;
  }

  .btn {
    margin: auto 0 0 0;
    text-align: center;
    background: ${mainBlue};
    border: 2px ${mainBlue} solid;
    color: ${mainWhite};
    font-size: 1rem;
    @media ${screen.withCursor} {
      &:hover {
        background: ${mainWhite};
        border: 2px ${mainWhite} solid;
        color: ${mainBlue};
      }
    }

    &--ad {
      display: block !important;
    }
  }
`;

const Ad = ({ label }) => {
  const { setIsFormPackaging } = useContext(StoreContext);
  return (
    <Wrapper>
      <div className="txt-wrap">
        <h6>Can’t find what you need?</h6>
        <p>Customise your packaging.</p>
      </div>

      <motion.button
        whileTap={{ scale: 0.96 }}
        className="btn btn--ad"
        onClick={() => {
          setIsFormPackaging(true);
        }}
      >
        TALK TO US
      </motion.button>
    </Wrapper>
  );
};

export default Ad;
