import React, { useEffect, useState } from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { motion } from "framer-motion";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { useMediaQuery } from "react-responsive";
import {
  useQueryParam,
  NumberParam,
  ArrayParam,
  withDefault,
} from "use-query-params";
import {
  mainBlue,
  mainWhite,
  mainBlack,
  transHover,
  innerWidth,
  screen,
} from "../components/variables";
import { ScrollLocky } from "react-scroll-locky";
import { MdClose } from "react-icons/md";
import { toSlug } from "../helpers";
import Tag from "../components/tag/thumbnail";
import Ad from "../components/ad";
import Spinner from "../images/svg/spinner.svg";
import { GrClear } from "react-icons/gr";

// motion framer animation props value
const container = {
  hidden: { opacity: 0, pointerEvents: "none" },
  show: {
    opacity: 1,
    pointerEvents: "all",
    transition: {
      staggerChildren: 0.15,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: -40 },
  show: { opacity: 1, y: 0 },
};

const Wrapper = styled.div`
  background: ${mainWhite};
  min-height: 100vh;
  padding: 0 0 90px 0;

  .main {
    padding: 35px 0 0 0;
    @media ${screen.xsmall} {
      padding: 0;
    }

    .active-categories {
      background: ${mainBlue};
      color: ${mainWhite};
      top: 0;
      left: 0;
      width: 100%;
      padding: 13px 0 10px 0;
      display: none;

      .category-list {
        display: flex;
        flex-wrap: wrap;

        &__each {
          font-weight: 300;
          font-size: 0.85rem;
          cursor: pointer;
          line-height: 1.5;
          @media ${screen.xsmall} {
            font-size: 0.95rem;
          }

          &--first {
            font-weight: 500;
            margin: 0 5px 0 0;
          }

          .slash {
            margin: 0 4px;
          }

          &:last-child {
            .slash {
              display: none;
            }
          }
        }
      }
    }

    .flex {
      max-width: ${innerWidth};
      padding: 35px 25px 0 25px;
      margin: 0 auto;
      @media ${screen.xsmall} {
        display: flex;
        padding: 80px 40px 0 40px;
      }
      @media ${screen.medium} {
        padding: 80px 0 0 0;
      }

      .filter-btn-mobile {
        background: ${mainBlue};
        color: ${mainWhite};
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 0 36px 0;
        padding: 16px 14px 13px 14px;
        position: relative;
        font-weight: 500;
        font-size: 0.9rem;
        border: 0;
        @media ${screen.xsmall} {
          display: none;
        }
        .plus {
          right: 12px;
          font-size: 1.2rem;
          position: absolute;
          top: 12px;
        }
      }

      .col {
        &--filters {
          background: ${mainWhite};
          position: fixed;
          z-index: 100;
          top: 50%;
          left: 50%;
          width: calc(100% - 80px);
          height: calc(100% - 80px);
          transform: translate(-50%, -50%);
          overflow-y: auto;
          padding: 50px 25px;
          @media ${screen.xsmall} {
            max-width: 226px;
            position: static;
            top: auto;
            left: auto;
            bottom: auto;
            right: auto;
            transform: translate(0);
            width: auto;
            height: auto;
            padding: 0;
            z-index: 1;
          }

          .categories {
            .title {
              font-weight: 500;
              font-size: 0.9rem;
              margin: 0 0 14px 0;
            }

            &__each {
              min-width: 220px;
              margin: 0 0 8px 0;

              .heading {
                position: relative;
                font-size: 1rem;
                padding: 13px 14px 12px 14px;
                cursor: pointer;
                transition: ${transHover};
                font-weight: 500;
                background: #e8e8ea;
                color: ${mainBlue};

                .toggle {
                  position: absolute;
                  right: 10px;
                  font-size: 1.2rem;
                  top: 50%;
                  transform: translateY(-50%);
                  pointer-events: none;
                  font-weight: 500;
                }

                .minus {
                  display: none;
                }
              }

              .option-list {
                border-left: 2px #e8e8ea solid;
                border-right: 2px #e8e8ea solid;
                border-bottom: 2px #e8e8ea solid;
                display: none;

                &__item {
                  padding: 9px 9px 9px 18px;
                  font-size: 0.95rem;
                  cursor: pointer;

                  .label {
                  }

                  &--active {
                    background: #e8e8ea;
                  }
                }
              }

              &--active {
                .heading {
                  background: ${mainBlue};
                  color: ${mainWhite};
                }

                .option-list {
                  display: block;
                }

                .plus {
                  display: none;
                }

                .minus {
                  display: block !important;
                }
              }
            }
          }

          .subcategories {
            margin: 55px 0 0 0;

            .title {
              font-weight: 500;
              font-size: 0.9rem;
              margin: 0 0 14px 0;
            }

            &__each {
              border-top: 2px solid #e8e8ea;

              .heading {
                padding: 13px 14px 12px 24px;
                position: relative;
                font-size: 1rem;
                font-weight: 500;
                cursor: pointer;

                .toggle {
                  position: absolute;
                  left: 7px;
                  font-size: 1.2rem;
                  top: 9px;
                  pointer-events: none;
                  font-weight: 500;
                  pointer-events: none;
                }

                .minus {
                  display: none;
                }
              }

              &:last-child {
                border-bottom: 2px solid #e8e8ea;
              }

              .option-list {
                display: none;
                padding: 0 0 24px 0;

                &__item {
                  padding: 9px 9px 9px 24px;
                  font-size: 0.95rem;
                  cursor: pointer;
                  @media ${screen.xsmall} {
                    padding: 9px 9px 9px 23px;
                  }

                  .label {
                    .checkbox {
                      border: 1px solid ${mainBlack};
                      width: 11px;
                      height: 11px;
                      display: inline-block;
                      margin: 0 7px 0 0;

                      &--active {
                        background: ${mainBlack};
                        pointer-events: none !important;
                      }
                    }
                  }

                  &--is-all {
                    pointer-events: none;
                  }
                }
              }

              &--active {
                .option-list {
                  display: block;
                }

                .plus {
                  display: none;
                }

                .minus {
                  display: block !important;
                }
              }
            }
          }

          .ad {
            display: none;
            @media ${screen.xsmall} {
              display: block;
              height: 316px;
              margin: 55px 0 0 0;
            }
          }

          .x-btn {
            color: ${mainBlue};
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 1.5rem;
            z-index: 110;
            @media ${screen.xsmall} {
              display: none;
            }
          }

          .clear-btn {
            font-weight: 500;
            font-size: 0.9rem;
            margin: 50px 0 0 0;
          }
        }

        &--products {
          width: 100%;
          @media ${screen.xsmall} {
            padding: 0 0 0 60px;
          }

          .top {
            margin: 0 0 20px 0;
            @media ${screen.small} {
              display: flex;
              justify-content: space-between;
            }

            .clear-btn {
              span {
                text-decoration: underline;
                cursor: pointer;
                font-size: 0.9rem;
                font-weight: 300;
                opacity: 0.75;
                display: block;
                line-height: 1.5;
              }
            }
          }

          .product-list {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            margin: 0 -12px;

            &__each {
              background: ${mainWhite};
              margin: 0 8px 28px 8px;
              position: relative;
              width: calc(50% - 16px);
              height: auto;
              cursor: pointer;
              overflow: hidden;
              @media ${screen.xsmall} {
                margin: 0 12px 24px 12px;
                width: calc(50% - 24px);
              }

              @media ${screen.small} {
                width: calc(33.33% - 24px);
              }

              @media ${screen.medium} {
                width: calc(25% - 24px);
              }

              .img {
                width: 100%;
                border: 1px #e8e8ea solid;
                position: relative;

                img {
                  transition: all 0.33s ease-out;
                }

                &::after {
                  content: "";
                  position: absolute;
                  background: rgba(32, 33, 33, 0.5);
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  transition: ${transHover};
                  z-index: 6;
                  opacity: 0;
                }
              }

              .txt {
                padding: 14px;
                border: 1px #e8e8ea solid;
                margin: 3px 0 0 0;
                position: relative;

                .name {
                  color: ${mainBlue};
                  font-size: 0.84rem;
                  font-weight: 500;
                  line-height: 1.4;
                  @media ${screen.xsmall} {
                    font-size: 0.94rem;
                  }
                }

                .specs {
                  font-size: 0.84rem;
                  margin: 6px 0 0 0;
                  @media ${screen.xsmall} {
                    font-size: 0.94rem;
                  }
                }

                &::after {
                  content: "LEARN MORE";
                  color: ${mainWhite};
                  position: absolute;
                  background: ${mainBlue};
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 0.95rem;
                  opacity: 0;
                  transition: ${transHover};
                }
              }

              .btn {
                display: block;
                background: ${mainBlue};
                color: ${mainWhite};
                white-space: pre;
                text-align: center;
                font-size: 0.84rem;
                width: 100%;
                padding: 8px 0 4px 0;
                @media ${screen.xsmall} {
                  display: none;
                }
              }

              @media ${screen.withCursor} {
                &:hover .txt::after,
                &:hover .img::after {
                  opacity: 1;
                }
                &:hover .img img {
                  transform: scale(1.06);
                }
              }
            }
          }
        }
      }
    }

    #overlay-transparent-bg {
      display: block;
      position: fixed;
      background: rgba(32, 33, 33, 0.85);
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      @media ${screen.xsmall} {
        display: none;
      }
    }
  }

  .pagination {
    margin: 0 0 20px 0;
    display: none;
    flex-wrap: wrap;
    @media ${screen.xsmall} {
      display: flex;
      justify-content: flex-end;
    }
    @media ${screen.small} {
      margin: 0;
    }

    .button {
      cursor: pointer;
      font-size: 0.9rem;
      margin: 0 15px 0 0;
      font-weight: 300;
      opacity: 0.75;
      line-height: 2.75;
      @media ${screen.xsmall} {
        margin: 0 0 0 12px;
        line-height: 1.5;
      }

      &--active {
        font-weight: 700;
        opacity: 1;
        pointer-events: none;
      }

      &:last-child {
        text-decoration: underline;
      }
    }

    &--bot {
      margin: 25px 0 0 0;
      display: flex;
      @media ${screen.xsmall} {
      }
    }
  }

  #loading-pagination {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      position: absolute;
      background: linear-gradient(
        to left bottom,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.35)
      );
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      svg {
        width: 190px;
        height: 190px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      background: rgba(255, 255, 255, 0.19);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      backdrop-filter: blur(10px);
    }
  }
`;

// returnd array of slug. only based on prismics data object
const getCategories = (arr, obj) => {
  const categories = arr.map((category) => {
    // some is null if left empty on prismic dashboard
    // prevents error
    if (category && category.category.document) {
      return toSlug(category.category.document.data.title.text);
    } else {
      return "";
    }
  });

  // returned string from prismic object
  const kind = obj.document ? toSlug(obj.document.data.title.text) : "";

  // combined to 1 array
  return [kind, ...categories];
};

// returned array of products from prismic. a cleaner object
const restructurePrismicProducts = (products) => {
  return products.map((product) => {
    return {
      id: product.product.document.id,
      name: product.product.document.data.title.text,
      categories: [
        product.product.document.data.kind.document &&
          product.product.document.data.kind.document.data.title.text,
        ...product.product.document.data.categories.map(
          (cat) => cat.category.document.data.title.text
        ),
        ...product.product.document.data.industries.map(
          (cat) => cat.industry.document.data.title.text
        ),
        product.product.document.data.tag,
      ],
      enviropak:
        product.product.document.data.enviropak.document &&
        product.product.document.data.enviropak.document.data.title.text,
      images:
        product.product.document.data.gallery.length !== 0 &&
        product.product.document.data.gallery[0].image.gatsbyImageData &&
        product.product.document.data.gallery[0],
      alt:
        product.product.document.data.gallery.length !== 0 &&
        product.product.document.data.gallery[0].image.gatsbyImageData &&
        product.product.document.data.gallery[0].image.alt,
      spec: product.product.document.data.highlighted_specification_value,
      tag: product.product.document.data.tag,
    };
  });
};

const ProductsPage = (props) => {
  // returned prismic data from graphql query below
  const { kinds, industries, enviropaks, specifications, categories, content } =
    props.data;

  const { title_tag, meta_description, all_products } = content.data;

  const isBrowser = typeof window !== "undefined";

  const isMobileView = useMediaQuery({ query: "(max-width: 649px)" });

  // for mobile - overlay filter
  const [isFilterOverlay, setIsFilterOverlay] = useState(false);

  // final array of products to render
  const [allProducts, setAllProducts] = useState([]);

  // store current active filter tab
  const [openedFilters, setOpenedFilters] = useQueryParam(
    "f",
    withDefault(ArrayParam, [])
  );

  // current product length. this will be persistent. thanks to useQueryParam
  const [currentPage, setCurrentPage] = useQueryParam(
    "p",
    withDefault(NumberParam, 1)
  );
  // current selected categories. this will be persistent
  const [selectedCategories, setSelectedCategories] = useQueryParam(
    "q",
    withDefault(ArrayParam, [])
  );

  // current selected enviropak. this will be persistent
  const [selectedEnviroPak, setSelectedEnviroPak] = useQueryParam(
    "e",
    withDefault(ArrayParam, [])
  );

  // loading state imitation. used only for user experience
  const [isLoading, setIsLoading] = useState(false);

  const [displayCategories, setDisplayCategories] = useState([]);

  // returned a cleaner array of objects
  const products = restructurePrismicProducts(all_products);

  useEffect(() => {
    // filtered products for render
    let filtered;

    if (selectedCategories.length === 0 && selectedEnviroPak.length === 0) {
      filtered = products;
    } else {
      filtered = products.filter((prod) => {
        return prod.categories.some((cat) => {
          return (
            selectedCategories.indexOf(toSlug(cat)) !== -1 ||
            selectedEnviroPak.indexOf(toSlug(prod.enviropak)) !== -1
          );
        });
      });
    }

    setAllProducts(filtered);
  }, [selectedCategories, selectedEnviroPak]);

  // click listener for closing filter dropdown on mobile use
  useEffect(() => {
    const userClick = (e) => {
      if (e.target.id === "overlay-transparent-bg") {
        setIsFilterOverlay(false);
      }
    };
    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);

  // ** start pagination handler
  const productsPerPage = 20;
  const handlePerPageClick = (e) => {
    setIsLoading(true);
    setTimeout(() => {
      setCurrentPage(Number(e.target.id));
      setIsLoading(false);
    }, 400);
  };
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts =
    currentPage === allProducts.length
      ? allProducts
      : allProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(allProducts.length / productsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => (
    <div
      key={number}
      id={number}
      className={currentPage === number ? "button button--active" : "button"}
      onClick={handlePerPageClick}
      role="button"
    >
      {number}
    </div>
  ));
  // ** end pagination handler

  // const handlePrev = () => {
  //   if (currentPage !== 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  // const handleNext = () => {
  //   if (currentPage <= pageNumbers.length - 1) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // useEffect(() => {
  //   if (props.location.state && props.location.state.isKind) {
  //     setOpenedFilters(["kind", ...openedFilters]);
  //   }

  //   if (props.location.state && props.location.state.isIndustry) {
  //     setOpenedFilters(["industry", ...openedFilters]);
  //   }
  // }, []);

  useEffect(() => {
    setIsFilterOverlay(!isMobileView);
  }, [isMobileView]);

  // structured array of dynamic subcategories, e.g. Material, Colour, Size
  const subcategories = specifications.edges.map((specs) => {
    return {
      spec: specs.node.data.title.text,
      subcategories: categories.edges.filter(
        (cat) =>
          cat.node.data.specification.document.data.title.text ===
          specs.node.data.title.text
      ),
    };
  });

  // select category handler
  const handleSelectCategory = (str) => {
    setIsLoading(true);

    setTimeout(() => {
      setCurrentPage(1);
      if (!selectedCategories.includes(toSlug(str))) {
        setSelectedCategories([toSlug(str), ...selectedCategories]);
      } else {
        // remove
        const filtered = selectedCategories.filter(
          (cat) => cat !== toSlug(str)
        );
        setSelectedCategories(filtered);
      }

      if (!displayCategories.includes(str)) {
        setDisplayCategories([str, ...displayCategories]);
      } else {
        // remove
        const filtered2 = displayCategories.filter((cat) => cat !== str);
        setDisplayCategories(filtered2);
      }

      setIsLoading(false);
    }, 400);
  };

  const handleSelectEnviroPak = (str) => {
    setIsLoading(true);

    setTimeout(() => {
      setCurrentPage(1);
      if (!selectedEnviroPak.includes(toSlug(str))) {
        setSelectedEnviroPak([toSlug(str), ...selectedEnviroPak]);
      } else {
        // remove
        const filtered = selectedEnviroPak.filter((cat) => cat !== toSlug(str));
        setSelectedEnviroPak(filtered);
      }

      setIsLoading(false);
    }, 400);
  };

  // open close filter handler
  const handleToggle = (e) => {
    // if don't exist add it
    if (openedFilters.indexOf(e.target.id) === -1) {
      setOpenedFilters([e.target.id, ...openedFilters]);
    } else {
      // if already existed in array remove it
      const filtered = openedFilters.filter((filter) => e.target.id !== filter);
      setOpenedFilters(filtered);
    }
  };

  // boolean checker whether is active used for class/style
  const ifCurrentOpen = (str) => {
    if (openedFilters.indexOf(str) === -1) return false;
    return true;
  };

  // boolean checker whether is active used for class/style
  const isActive = (str) => {
    if (selectedCategories.indexOf(toSlug(str)) === -1) return false;
    return true;
  };

  const isEnviroPakActive = (str) => {
    if (selectedEnviroPak.indexOf(toSlug(str)) === -1) return false;
    return true;
  };

  const isPagination = allProducts.length !== 0 && pageNumbers.length !== 1;

  const isCategorySelected =
    selectedEnviroPak.length + selectedCategories.length !== 0;

  const handleAllCategory = (str) => {
    // return array of categories based on selected specification e.g. Material
    const getSubcategories = subcategories.filter((sub) => sub.spec === str)[0];
    // transform to array of slugified string
    const subs = getSubcategories.subcategories.map((item) =>
      toSlug(item.node.data.title.text)
    );

    // array comparison. only return not releated to specification
    const filteredCategories = selectedCategories.filter((each) => {
      return subs.indexOf(each) === -1;
    });

    setIsLoading(true);

    setTimeout(() => {
      setSelectedCategories(filteredCategories);
      setIsLoading(false);
    }, 400);
  };

  const isCurrentAllSpecActive = (str) => {
    if (str) {
      // return array of categories based on selected specification e.g. Material
      const getSubcategories = subcategories.filter(
        (sub) => sub.spec === str
      )[0];
      // transform to array of slugified string
      const subs = getSubcategories.subcategories.map((item) =>
        toSlug(item.node.data.title.text)
      );
      const isActive = selectedCategories.filter((each) => {
        return subs.indexOf(each) !== -1;
      }).length;

      return !isActive;
    } else {
      return;
    }
  };

  const handleClearAll = () => {
    setIsLoading(true);
    setTimeout(() => {
      setSelectedCategories([]);
      setSelectedEnviroPak([]);
      setCurrentPage(1);
      setOpenedFilters([]);
      setIsLoading(false);
    }, 400);
  };

  return (
    <Layout rotatedSquareColour={mainWhite}>
      <Seo title={title_tag} description={meta_description} />

      <Wrapper>
        {isBrowser && (
          <div className="main">
            {displayCategories.length !== 0 && (
              <div className="active-categories">
                <ul className="category-list inner-wrapper">
                  <li className="category-list__each category-list__each--first">
                    Active:{" "}
                  </li>
                  {displayCategories.reverse().map((item) => (
                    <li
                      className="category-list__each"
                      key={item}
                      onClick={() => {
                        handleSelectCategory(item);
                      }}
                      title="Remove"
                    >
                      {item}
                      <span className="slash">/</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {isFilterOverlay && <div id="overlay-transparent-bg"></div>}

            <div className="flex">
              {isMobileView && (
                <ScrollLocky
                  isolation={false}
                  enabled={isMobileView && isFilterOverlay}
                >
                  <motion.div
                    initial={isMobileView ? "hidden" : "show"}
                    animate={isFilterOverlay ? "show" : "hidden"}
                    variants={container}
                    className="col col--filters"
                  >
                    <div className="categories">
                      <p className="title">VIEW PRODUCTS BY</p>
                      <motion.div
                        className={
                          ifCurrentOpen("kind")
                            ? "categories__each categories__each--active"
                            : "categories__each"
                        }
                        variants={item}
                      >
                        <h3
                          className="heading"
                          id="kind"
                          onClick={handleToggle}
                        >
                          Kind
                          <span className="toggle minus">&minus;</span>
                          <span className="toggle plus">+</span>
                        </h3>

                        <ul className="option-list">
                          {kinds.edges.map((item) => (
                            <li
                              onClick={() => {
                                handleSelectCategory(item.node.data.title.text);
                              }}
                              className={
                                isActive(item.node.data.title.text)
                                  ? "option-list__item option-list__item--active"
                                  : "option-list__item"
                              }
                              key={item.node.id}
                            >
                              <span className="label" role="button">
                                {item.node.data.title.text}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </motion.div>

                      <motion.div
                        className={
                          ifCurrentOpen("industry")
                            ? "categories__each categories__each--active"
                            : "categories__each"
                        }
                        variants={item}
                      >
                        <h3
                          className="heading"
                          id="industry"
                          onClick={handleToggle}
                        >
                          Industry
                          <span className="toggle minus">&minus;</span>
                          <span className="toggle plus">+</span>
                        </h3>

                        <ul className="option-list">
                          {industries.edges.map((item) => (
                            <li
                              className={
                                isActive(item.node.data.title.text)
                                  ? "option-list__item option-list__item--active"
                                  : "option-list__item"
                              }
                              onClick={() => {
                                handleSelectCategory(item.node.data.title.text);
                              }}
                              key={item.node.id}
                            >
                              <span className="label" role="button">
                                {item.node.data.title.text}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </motion.div>

                      <motion.div
                        className={
                          ifCurrentOpen("enviropak")
                            ? "categories__each categories__each--active"
                            : "categories__each"
                        }
                        variants={item}
                      >
                        <h3
                          className="heading"
                          id="enviropak"
                          onClick={handleToggle}
                        >
                          EnviroPak
                          <span className="toggle minus">&minus;</span>
                          <span className="toggle plus">+</span>
                        </h3>

                        <ul className="option-list">
                          {enviropaks.edges.map((item) => (
                            <li
                              className={
                                isEnviroPakActive(item.node.data.title.text)
                                  ? "option-list__item option-list__item--active"
                                  : "option-list__item"
                              }
                              onClick={() => {
                                handleSelectEnviroPak(
                                  item.node.data.title.text
                                );
                              }}
                              key={item.node.id}
                            >
                              <span className="label" role="button">
                                {item.node.data.title.text}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </motion.div>
                    </div>

                    <motion.div className="subcategories" variants={item}>
                      <p className="title">FILTER RANGE BY</p>

                      {subcategories.map((item, i) => (
                        <div
                          className={
                            ifCurrentOpen(toSlug(item.spec))
                              ? "subcategories__each subcategories__each--active"
                              : "subcategories__each"
                          }
                          key={item.spec + i}
                        >
                          <h3
                            className="heading"
                            id={toSlug(item.spec)}
                            onClick={handleToggle}
                          >
                            <span className="toggle minus">&minus;</span>
                            <span className="toggle plus">+</span>
                            {item.spec}
                          </h3>
                          <ul className="option-list">
                            <li
                              className={
                                isCurrentAllSpecActive(item.spec)
                                  ? "option-list__item option-list__item--is-all"
                                  : "option-list__item"
                              }
                              onClick={() => {
                                handleAllCategory(item.spec);
                              }}
                            >
                              <div className="label" role="button">
                                <span
                                  className={
                                    isCurrentAllSpecActive(item.spec)
                                      ? "checkbox checkbox--active"
                                      : "checkbox"
                                  }
                                  role="checkbox"
                                ></span>
                                All
                              </div>
                            </li>
                            {item.subcategories.map((sub) => (
                              <li
                                className="option-list__item"
                                onClick={() => {
                                  handleSelectCategory(
                                    sub.node.data.title.text
                                  );
                                }}
                                key={sub.node.data.title.text}
                              >
                                <div className="label" role="button">
                                  <span
                                    className={
                                      isActive(sub.node.data.title.text)
                                        ? "checkbox checkbox--active"
                                        : "checkbox"
                                    }
                                    role="checkbox"
                                  ></span>
                                  {sub.node.data.title.text}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </motion.div>

                    <div className="ad">
                      <Ad />
                    </div>

                    <span
                      className="x-btn"
                      onClick={() => {
                        setIsFilterOverlay(false);
                      }}
                      role="button"
                    >
                      <MdClose />
                    </span>
                  </motion.div>
                </ScrollLocky>
              )}

              {!isMobileView && (
                <div className="col col--filters">
                  <div className="categories">
                    <p className="title">VIEW PRODUCTS BY</p>
                    <div
                      className={
                        ifCurrentOpen("kind")
                          ? "categories__each categories__each--active"
                          : "categories__each"
                      }
                    >
                      <h3 className="heading" id="kind" onClick={handleToggle}>
                        Kind
                        <span className="toggle minus">&minus;</span>
                        <span className="toggle plus">+</span>
                      </h3>

                      <ul className="option-list">
                        {kinds.edges.map((item) => (
                          <li
                            onClick={() => {
                              handleSelectCategory(item.node.data.title.text);
                            }}
                            className={
                              isActive(item.node.data.title.text)
                                ? "option-list__item option-list__item--active"
                                : "option-list__item"
                            }
                            key={item.node.id}
                          >
                            <span className="label" role="button">
                              {item.node.data.title.text}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div
                      className={
                        ifCurrentOpen("industry")
                          ? "categories__each categories__each--active"
                          : "categories__each"
                      }
                    >
                      <h3
                        className="heading"
                        id="industry"
                        onClick={handleToggle}
                      >
                        Industry
                        <span className="toggle minus">&minus;</span>
                        <span className="toggle plus">+</span>
                      </h3>

                      <ul className="option-list">
                        {industries.edges.map((item) => (
                          <li
                            className={
                              isActive(item.node.data.title.text)
                                ? "option-list__item option-list__item--active"
                                : "option-list__item"
                            }
                            onClick={() => {
                              handleSelectCategory(item.node.data.title.text);
                            }}
                            key={item.node.id}
                          >
                            <span className="label" role="button">
                              {item.node.data.title.text}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div
                      className={
                        ifCurrentOpen("enviropak")
                          ? "categories__each categories__each--active"
                          : "categories__each"
                      }
                    >
                      <h3
                        className="heading"
                        id="enviropak"
                        onClick={handleToggle}
                      >
                        EnviroPak
                        <span className="toggle minus">&minus;</span>
                        <span className="toggle plus">+</span>
                      </h3>

                      <ul className="option-list">
                        {enviropaks.edges.map((item) => (
                          <li
                            className={
                              isEnviroPakActive(item.node.data.title.text)
                                ? "option-list__item option-list__item--active"
                                : "option-list__item"
                            }
                            onClick={() => {
                              handleSelectEnviroPak(item.node.data.title.text);
                            }}
                            key={item.node.id}
                          >
                            <span className="label" role="button">
                              {item.node.data.title.text}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="subcategories" variants={item}>
                    <p className="title">FILTER RANGE BY</p>

                    {subcategories.map((item, i) => (
                      <div
                        className={
                          ifCurrentOpen(toSlug(item.spec))
                            ? "subcategories__each subcategories__each--active"
                            : "subcategories__each"
                        }
                        key={item.spec + i}
                      >
                        <h3
                          className="heading"
                          id={toSlug(item.spec)}
                          onClick={handleToggle}
                        >
                          <span className="toggle minus">&minus;</span>
                          <span className="toggle plus">+</span>
                          {item.spec}
                        </h3>
                        <ul className="option-list">
                          <li
                            className={
                              isCurrentAllSpecActive(item.spec)
                                ? "option-list__item option-list__item--is-all"
                                : "option-list__item"
                            }
                            onClick={() => {
                              handleAllCategory(item.spec);
                            }}
                          >
                            <div className="label" role="button">
                              <span
                                className={
                                  isCurrentAllSpecActive(item.spec)
                                    ? "checkbox checkbox--active"
                                    : "checkbox"
                                }
                                role="checkbox"
                              ></span>
                              All
                            </div>
                          </li>
                          {item.subcategories.map((sub) => (
                            <li
                              className="option-list__item"
                              onClick={() => {
                                handleSelectCategory(sub.node.data.title.text);
                              }}
                              key={sub.node.data.title.text}
                            >
                              <div className="label" role="button">
                                <span
                                  className={
                                    isActive(sub.node.data.title.text)
                                      ? "checkbox checkbox--active"
                                      : "checkbox"
                                  }
                                  role="checkbox"
                                ></span>
                                {sub.node.data.title.text}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>

                  <div className="ad">
                    <Ad />
                  </div>
                </div>
              )}
              <button
                className="filter-btn-mobile"
                onClick={() => {
                  setIsFilterOverlay(true);
                }}
              >
                <span>FILTER PRODUCTS</span>
                <span className="plus">+</span>
              </button>

              <div className="col col--products">
                <div className="top">
                  <p role="button" className="clear-btn">
                    {isCategorySelected && (
                      <span onClick={handleClearAll}>Clear all filter</span>
                    )}
                  </p>

                  {currentPage < allProducts.length && isPagination && (
                    <div className="pagination">
                      {renderPageNumbers}
                      <div
                        id={allProducts.length}
                        className="button"
                        onClick={handlePerPageClick}
                        role="button"
                      >
                        View all
                      </div>
                    </div>
                  )}
                </div>
                <ul className="product-list">
                  {currentProducts.map((prod, i) => (
                    <li
                      className="product-list__each"
                      onClick={() => {
                        navigate(`/products/${toSlug(prod.name)}`);
                      }}
                      key={prod.id + i}
                    >
                      <figure className="img">
                        {prod.images ? (
                          <GatsbyImage
                            image={prod.images.image.gatsbyImageData}
                            alt={prod.alt || "Featured"}
                          />
                        ) : (
                          <StaticImage
                            src="../images/fallback-gallery.jpg"
                            alt="Thumbnail"
                          />
                        )}
                      </figure>
                      <div className="txt">
                        <h3 className="name">{prod.name}</h3>
                        <h3 className="specs">{prod.spec}</h3>
                      </div>
                      <button className="btn">LEARN MORE</button>
                      <Tag label={prod.tag} />
                    </li>
                  ))}
                </ul>

                {currentPage < allProducts.length && isPagination && (
                  <div className="pagination pagination--bot">
                    {renderPageNumbers}
                    <div
                      id={allProducts.length}
                      className="button"
                      onClick={handlePerPageClick}
                      role="button"
                    >
                      View all
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {isLoading && (
          <div id="loading-pagination">
            <span className="icon">
              <Spinner />
            </span>
          </div>
        )}
      </Wrapper>
    </Layout>
  );
};

export default ProductsPage;

export const dataQuery = graphql`
  {
    content: prismicMainProductsPage {
      data {
        meta_description
        title_tag
        all_products {
          product {
            document {
              ... on PrismicProduct {
                id
                data {
                  gallery {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  tag
                  highlighted_specification_value
                  title {
                    text
                  }
                  categories {
                    category {
                      document {
                        ... on PrismicCategory {
                          data {
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                  enviropak {
                    document {
                      ... on PrismicEnviropak {
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  industries {
                    industry {
                      document {
                        ... on PrismicIndustry {
                          data {
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                  kind {
                    document {
                      ... on PrismicKind {
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    kinds: allPrismicKind(sort: { fields: data___title___text, order: ASC }) {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
    industries: allPrismicIndustry(
      sort: { fields: data___title___text, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
    enviropaks: allPrismicEnviropak(
      sort: { fields: data___title___text, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
    specifications: allPrismicSpecification {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
    categories: allPrismicCategory {
      edges {
        node {
          id
          data {
            title {
              text
            }
            specification {
              document {
                ... on PrismicSpecification {
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
